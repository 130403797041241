import { gql } from "@apollo/client";

export const GET_TOP_ASSET_FINDINGS = gql`
  query GetTopAssetFindings(
    $scopeMrn: String!
    $first: Int
    $after: String
    $orderBy: FindingsOrder
    $filter: FindingsFilter
  ) {
    findings(
      scopeMrn: $scopeMrn
      first: $first
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      ... on FindingsConnection {
        edges {
          cursor
          node {
            ... on CheckFinding {
              mrn
              id
              title
              state
              iconId
              baseScore
              riskValue
              rating
              riskFactors {
                mrn
                indicator
                title
                affected
                total
              }
              lastUpdated
              tags {
                key
                value
              }
              asset {
                id
                mrn
                name
                icon
                lastUpdated
                platform {
                  name
                  title
                  arch
                  version
                  kind
                  runtime
                  release
                  family
                  labels {
                    key
                    value
                  }
                }
              }
              exception {
                id
                reviewStatus
                action
                justification
              }
              resultType
              mquery {
                uid
                mrn
                title
                docs {
                  desc
                  remediations {
                    ... on Remediations {
                      entries {
                        id
                        desc
                      }
                    }
                    ... on AuthenticationRequired {
                      message
                      code
                    }
                  }
                  references {
                    title
                    url
                  }
                  audit
                  remediation
                }
                impact {
                  value
                  rating
                }
                mql
                properties {
                  uid
                  mrn
                  title
                  mql
                  setByPolicyMrn
                }
                parent
                variants {
                  id
                  mrn
                  title
                  icon
                }
                variantQueries {
                  uid
                  mrn
                  title
                  mql
                  filter
                  filterID
                }
                action
                code
                codeId
                severity
              }
            }
            ... on CveFinding {
              mrn
              id
              asset {
                id
                mrn
                name
                icon
                lastUpdated
                platform {
                  name
                  title
                  arch
                  version
                  kind
                  runtime
                  release
                  family
                  labels {
                    key
                    value
                  }
                }
              }
              title
              state
              iconId
              epss {
                probability
                percentile
              }
              cvss {
                id
                value
                type
                vector
                source
                rating
              }
              riskValue
              rating
              riskFactors {
                mrn
                indicator
                title
                affected
                total
              }
              firstDetectedAt
              publishedAt
              tags {
                key
                value
              }
              lastUpdated
              detectionSources {
                name
                url
                firstDetectedAt
                lastUpdatedAt
                fixedAt
                vendor
                version
              }
              exception {
                id
                reviewStatus
                action
                justification
              }
            }
            ... on AdvisoryFinding {
              mrn
              id
              asset {
                id
                mrn
                name
                icon
                lastUpdated
                platform {
                  name
                  title
                  arch
                  version
                  kind
                  runtime
                  release
                  family
                  labels {
                    key
                    value
                  }
                }
              }
              title
              state
              iconId
              epss {
                probability
                percentile
              }
              cvss {
                id
                value
                type
                vector
                source
                rating
              }
              riskValue
              rating
              riskFactors {
                mrn
                indicator
                title
                affected
                total
              }
              firstDetectedAt
              publishedAt
              tags {
                key
                value
              }
              lastUpdated
              detectionSources {
                name
                url
                firstDetectedAt
                lastUpdatedAt
                fixedAt
                vendor
                version
              }
              exception {
                id
                reviewStatus
                action
                justification
              }
            }
          }
        }
      }
    }
  }
`;
