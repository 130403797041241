import { gql } from "@apollo/client";

export const UPDATE_SECURITY_MODEL = gql`
  mutation UpdateSecurityModel($input: UpdateSecurityModelInput!) {
    updateSecurityModel(input: $input) {
      scopeMrn
      slas {
        findings {
          rating
          daysToResolve
          daysBeforeWarning
        }
      }
    }
  }
`;
